import store from '@/store'

const talkMeVar = 'TalkMe'
const talkMeUserSet = 'talkMeUserSet'
export const Talkme = {
  init: (domain, currentUser) => {
    window.TalkMeSetup = { domain: domain, clientId: currentUser?.id }
    ;(function (d, w, m) {
      window.supportAPIMethod = m
      var s = d.createElement('script')
      s.type = 'text/javascript'
      s.id = 'supportScript'
      s.charset = 'utf-8'
      s.async = true
      var id = 'df8e02a02743bfb44a886fe394d32bcd'
      s.src = '//lcab.talk-me.ru/support/support.js?h=' + id
      var sc = d.getElementsByTagName('script')[0]
      w[m] =
        w[m] ||
        function () {
          ;(w[m].q = w[m].q || []).push(arguments)
        }
      if (sc) sc.parentNode.insertBefore(s, sc)
      else d.documentElement.firstChild.appendChild(s)
    })(document, window, talkMeVar)

    if (currentUser && window[talkMeVar]) {
      window[talkMeVar]('setClientInfo', {
        name: currentUser?.profile?.display_name || currentUser?.username,
        phone: currentUser?.phone,
        email: currentUser?.email,
        custom: {
          account: currentUser?.id,
          userLevel: currentUser?.status?.cur_level,
        },
      })
    }
  },
  setUserInfo: currentUser => {
    if (window[talkMeUserSet]) {
      return
    }

    if (!currentUser || !window[talkMeVar]) return
    window[talkMeVar]('setClientInfo', {
      name: currentUser.profile?.display_name || currentUser?.username,
      phone: currentUser.phone,
      email: currentUser.email,
      custom: {
        account: currentUser.id,
        userLevel: currentUser?.status?.cur_level,
      },
    })
    window[talkMeUserSet] = true
  },
  open: () => {
    if (!window[talkMeVar]) {
      return
    }
    window[talkMeVar]('openSupport')
  },
}
